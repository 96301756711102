import React from 'react'
import IconButton from 'components/elements/IconButton'
import SvgIcon from 'components/elements/SvgIcon'

const Icon = () => {
  return (
    <IconButton aria-label="Delete">
      <SvgIcon>
        <path
          fill="#FFF"
          d="M611.3,434.32a5.06,5.06,0,0,0,5.05-5.05v-19a4.51,4.51,0,0,0-4.44-4.5v-.58a4.05,4.05,0,0,0-4.39-4v-2.6a2.69,2.69,0,0,0-4.8-1.66l-6.48,8.25-6.47-8.25a2.69,2.69,0,0,0-4.8,1.66v2.6a4.05,4.05,0,0,0-4.4,4v.58a4.51,4.51,0,0,0-4.44,4.5v19a5.06,5.06,0,0,0,5,5.05H611.3Zm-33.17-5.05v-19a2.5,2.5,0,0,1,2.44-2.5v18a3.14,3.14,0,0,0,1.94,2.91l8.76,3.67H581.18A3.06,3.06,0,0,1,578.13,429.27Zm4.44-23.5v-.58a2,2,0,0,1,.91-1.7,2,2,0,0,1,1.13-.35,1.47,1.47,0,0,1,.36,0v16.25a3.31,3.31,0,0,0,.71,2.05l7.4,9.43-9.8-4.1a1.16,1.16,0,0,1-.71-1.07v-20Zm4.39-4v-3.23a.67.67,0,0,1,.47-.65.7.7,0,0,1,.24,0,.67.67,0,0,1,.53.27l4.87,6.2,1.12,1.43.14.18.64.82.27.34v23.32l-8-10.18a1.28,1.28,0,0,1-.29-.82V401.79Zm12.46,2.55,4.87-6.2a.69.69,0,0,1,1.23.42v20.87a1.33,1.33,0,0,1-.28.82l-8,10.18V407.11l.27-.34.64-.82.15-.18Zm9.58-.85a2,2,0,0,1,.91,1.7v20.55a1.16,1.16,0,0,1-.71,1.07l-9.8,4.1,7.41-9.43a3.31,3.31,0,0,0,.71-2.05V403.18A2,2,0,0,1,609,403.49Zm5.35,25.78a3.06,3.06,0,0,1-3.05,3.05H601.21l8.76-3.67a3.14,3.14,0,0,0,1.94-2.91v-18a2.5,2.5,0,0,1,2.44,2.5Z"
          transform="translate(-346, -237) scale(0.6)"
        />
      </SvgIcon>
    </IconButton>
  )
}

export default Icon
