/** @type {any} */

export default {
  button: {
    position: 'absolute',
    top: '15px',
    right: '15px',
  },
  text: {
    marginLeft: '10px',
  },
}
